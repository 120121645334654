import React from 'react'
import { useEffect, useState } from 'react';
import './Location.css'
import './Home.css'
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import Home_Hero_One from '../images/Tattva Promo 1.mp4'
import Home_Hero_One_Tablet from '../images/Tattva Hyderabad Promo (Mobile Version).mp4'
import Home_Hero_One_Mobile from '../images/Tattva Hyderabad Promo (Mobile Version).mp4'
import Home_Project_One from '../images/home-project-one-1.jpg';
import Home_Project_Two from '../images/home-project-two.jpg';
import Home_Project_Three from '../images/home-project-three.jpg';
import Home_Project_Four from '../images/home-project-four.jpg';
import Home_Project_Five from '../images/home-project-five.jpg';
import Home_Project_Six from '../images/home-project-six.jpg';
import Home_Project_Seven from '../images/home-project-seven.jpg';
import Home_Project_Eight from '../images/home-project-eight.jpg';
import Home_Project_Nine from '../images/home-project-nine.jpeg';
import Home_Project_Ten from '../images/asbl-project.jpg';
import Home_Project_Eleven from '../images/asbl-spectra.jpg';

function Hyderabad() {

    // State to manage the theme mode (light/dark)
    const [theme, setTheme] = useState('light'); // Default theme

    useEffect(() => {
        // Check for stored theme in localStorage, and apply it.
        const currentTheme = localStorage.getItem('theme') || 'light';
        setTheme(currentTheme);
    }, []);


    return (
        <div className='location-main'>

            {/* Hyderbad Hero */}
            <div className='about-hero hyd-hero'>
                <div className='about-hero-inner d-flex justify-content-center align-items-center'>
                    <div>
                        <h1 className='syne-title'>HYDERABAD<span className='text-rose'>.</span></h1>
                        <p className='text-center'>Home / <span className='text-rose'>Projects</span></p>
                    </div>
                </div>
            </div>
            {/* Hyderbad Hero */}
            {/* Hyderbad Video */}
            <div className='hyd-main'>
                <video src={Home_Hero_One} autoPlay loop muted className='video-full d-lg-block d-none'></video>
                <video src={Home_Hero_One_Tablet} autoPlay loop muted className='video-full d-md-block d-lg-none d-none '></video>
                <video src={Home_Hero_One_Mobile} autoPlay loop muted className='video-full d-block d-md-none '></video>
            </div>
            {/* Hyderbad Video */}
            {/* Home Projects */}
            <div className='home-landowner container-fluid py-4'>
                <div className='home-landowner-inner container py-4'>
                    <h1 className='syne-title text-center mb-3'>Our Projects<span className='text-rose'>.</span></h1>
                    <p className='mb-4 text-secondary'>Our Project Partnership offers property owners a unique chance to collaborate with Tattva Hi Life in lucrative real estate developments. By partnering with us, owners can leverage their land to secure a substantial share in project profits without the complexities of construction management. With our expert guidance and commitment to transparency, we help property owners maximize their returns while they retain control over their valuable assets.</p>
                    <div className='home-land-cards'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_Ten} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>ASBL Spire</h4>
                                        <p className='mb-1'>Kokapet, Hyderabad</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-asbl-spire" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_Eleven} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>ASBL Spectra</h4>
                                        <p className='mb-1'>Financial District</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-asbl-spectra" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_One} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>IRIS By Raghava</h4>
                                        <p className='mb-1'>Raidugam, Hyderabad</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-iris" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_Two} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>Wave By Raghava</h4>
                                        <p className='mb-1'>Tellapur, Hyderabad</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-wave" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_Three} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>Pearl By Auro Reality</h4>
                                        <p className='mb-1'>Hi-Tech City, Hyderabad</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-pearl" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_Four} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>Rajpushpa Pristinia</h4>
                                        <p className='mb-1'>Kokapet, Hyderabad</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-rajpushpa" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_Five} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>My Home Apas</h4>
                                        <p className='mb-1'>Kokapet, Hyderabad</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-my-home" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_Six} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>Upcoming Luxury Apartments</h4>
                                        <p className='mb-1'>Neopolis, Kokapet</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-upcoming" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_Seven} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>Upcoming Luxury Apartments</h4>
                                        <p className='mb-1'>Narsingi, Hyderabad</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-upcoming" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_Eight} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>Candeur Lakescape</h4>
                                        <p className='mb-1'>Kondapur, Hyderabad</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-candeur" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_Nine} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>Luxury Apartments</h4>
                                        <p className='mb-1'>Kondapur, Hyderabad</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-luxury" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Home Projects */}
            {/* Home Discuss*/}
            <div className='container-fluid py-5 home-discuss'>
                <div className='container py-5 home-discuss-inner bg-light p-4 rounded-15 shadow-sm'>
                    <h1 className='syne-title mb-4 text-start'>
                        LET'S DISCUSS<br />YOUR IDEA<span className='text-rose'>.</span>
                    </h1>
                    <div className='discuss-inner'>
                        <div className='row align-items-center'>
                            <div className='col-lg-6'>
                                <div className='discuss-left text-start'>
                                    <p className='text-secondary'>
                                        We’re excited to hear your vision! Whether you have a concept or need help brainstorming, our team is ready to collaborate. Let’s connect and turn your idea into reality—schedule a meeting with us today!
                                    </p>
                                    <div className='discuss-mail d-flex align-items-center my-3'>
                                        <i className='fas fa-envelope me-2'></i>
                                        <p className='mb-0 text-secondary'>rakesh.tattvahilife@gmail.com</p>
                                    </div>
                                    <div className='discuss-mail d-flex align-items-center my-3'>
                                        <i className='fas fa-mobile-alt me-2'></i>
                                        <p className='mb-0 text-secondary'>+91 88970 35800</p>
                                    </div>
                                    <div className='discuss-mail d-flex align-items-center my-3'>
                                        <i className='fas fa-map-marker-alt me-2'></i>
                                        <p className='mb-0 text-secondary'>10, Prashanthi Hills, Khajaguda, Gachibowli, Hyderabad</p>
                                    </div>
                                    <div className='discuss-icons d-flex'>
                                        <a href='/about' target='blank' className='text-decoration-none'><i className='fab fa-linkedin-in text-white'></i></a>
                                        <a href='#' className='text-decoration-none'><i className='fab fa-twitter text-white'></i></a>
                                        <a href='#' className='text-decoration-none'><i className='fab fa-facebook-f text-white'></i></a>
                                        <a href='#' className='text-decoration-none'><i className='fab fa-instagram text-white'></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <form action="https://api.web3forms.com/submit" method="POST">
                                    <input type="hidden" name="access_key" value="d567d7ce-8cc7-4fdd-a9ae-66c9c3461e68" />
                                    <input
                                        type='text'
                                        placeholder='Name'
                                        name="name"
                                        className='form-control my-2'
                                        required

                                    />
                                    <input
                                        type='text'
                                        placeholder='Mobile'
                                        name="mobile"
                                        className='form-control my-2'
                                        required
                                    />
                                    <input
                                        type='email'
                                        placeholder='Email'
                                        name="email"
                                        className='form-control my-2'
                                        required
                                    />
                                    <textarea
                                        placeholder='Message'
                                        name="message"
                                        rows='4'
                                        className='form-control my-2'
                                        required
                                    />
                                    <div className='text-start mt-3 d-flex align-items-center'>
                                        <span className='fs-2 text-rose'>→</span>
                                        <input type='submit' value='Send' className='border-0 text-rose discuss-btn' />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Home Discuss*/}
        </div>
    )
}

export default Hyderabad