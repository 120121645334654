import React from 'react'
import Home_Hero_Three from '../images/Banglore-video.mp4'


function Banglore() {
    return (
        <div className='location-main'>
            {/* banglore Hero */}
            <div className='banglore-hero'>
                <video src={Home_Hero_Three} autoPlay loop muted className='video-full'></video>
            </div>
            {/* banglore Hero */}
        </div>
    )
}

export default Banglore