import React from 'react'
import './Projects.css'
import './Home.css'
import Reportage_Img from '../images/reportage-village.jpg'
import Project_Gallery_One from '../images/project-gallery-one.png'
import Project_Gallery_Two from '../images/project-gallery-two.png'
import Project_Gallery_Three from '../images/project-gallery-three.png'
import Project_Gallery_Four from '../images/project-gallery-four.png'
import Project_Gallery_Five from '../images/project-gallery-five.png'
import Project_Gallery_Six from '../images/project-gallery-six.png'
import Project_Gallery_Seven from '../images/project-gallery-seven.png'
import Project_Gallery_Eight from '../images/project-gallery-eight.png'

function ReportedVillage() {
    return (
        <div className="projects-main">
            {/* WAVE Hero */}
            <div className='about-hero'>
                <div className='about-hero-inner d-flex justify-content-center align-items-center'>
                    <div>
                        <h1 className='syne-title'>REPORTAGE VILLAGE<span className='text-rose'>.</span></h1>
                        <p className='text-center'>Home / <span className='text-rose'>Projects</span></p>
                    </div>
                </div>
            </div>
            {/* WAVE Hero */}
            {/* WAVE About */}
            <div className='iris-about container-fluid py-4'>
                <div className='iris-about-inner container py-4'>
                    <div className='row'>
                        <div className='col-lg-6 my-3'>
                            <img src={Reportage_Img} className='img-fluid rounded-10 shadow' />
                        </div>
                        <div className='col-lg-6 my-3 d-flex justify-content-center align-items-center'>
                            <div className='iris-about-content p-2'>
                                <h4 className='syne-title'>WELCOME TO <br /><span className='text-rose fs-3'>REPORTAGE VILLAGE</span> </h4>
                                <p className='text-secondary'>"Reportage Village" is an exceptional residential project that seamlessly blends modern architecture with high standards of luxury living. Strategically located in a vibrant urban setting, Reportage Village offers a range of spacious 2, 3, and 4 BHK apartments, each meticulously designed with contemporary aesthetics and attention to detail. These residences are crafted to capture abundant natural light and feature premium finishes, providing a sophisticated yet comfortable living space. Committed to sustainability, Reportage Village incorporates eco-friendly features such as rainwater harvesting and energy-efficient systems, creating a harmonious balance between luxury and environmental stewardship.</p>
                                <p className='text-secondary'>Residents of Reportage Village enjoy access to a variety of premium amenities, including a state-of-the-art gym, a tranquil swimming pool, beautifully landscaped gardens, and 24/7 security for added peace of mind.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* WAVE About */}
            {/* WAVE Benefits */}
            <div className='landowner-benefit container-fluid py-4 bg-light'>
                <div className='landowner-benefit-inner container py-4'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <h4 className='syne-title text-lg-end'>WHY CHOOSE<br /><span className='text-rose fs-3'>REPORTAGE VILLAGE</span> </h4>

                        </div>
                        <div className='col-lg-6'>
                            <p className='text-secondary'>Reportage Village offers a luxury living experience marked by exceptional craftsmanship, sustainable design, and energy efficiency. With a focus on customer satisfaction, Reportage Village provides a seamless home-buying journey that elegantly blends comfort with modern sophistication.</p>
                        </div>
                    </div>

                    <div className="container my-5">
                        <div className="row g-4">

                            {/* <!-- Card 1: Landowner's Share of Flats & Villas --> */}
                            <div className="col-lg-4 col-md-6">
                                <div className="card h-100 text-center p-4 border-0 shadow-sm">
                                    <div className="card-icon mb-3">
                                        <i class="fas fa-award fa-3x text-rose"></i>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title">Excellence in Craftsmanship</h5>
                                        <p className="text-secondary">Our projects are built with meticulous attention to detail and superior quality materials, ensuring long-lasting beauty and durability. Every design is crafted to create living spaces that inspire, blending functionality with luxury.</p>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- Card 2: Discounted Price --> */}
                            <div className="col-lg-4 col-md-6">
                                <div className="card h-100 text-center p-4 border-0 shadow-sm">
                                    <div className="card-icon mb-3">
                                        <i class="fas fa-leaf fa-3x text-rose"></i>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title">Sustainable Living Solutions</h5>
                                        <p className="text-secondary">From eco-friendly building materials to green technologies like rainwater harvesting and solar-powered lighting, our projects are designed to minimize environmental impact while providing energy-efficient homes. We build for today, with a focus on a better tomorrow.</p>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- Card 3: Better Availability --> */}
                            <div className="col-lg-4 col-md-6">
                                <div className="card h-100 text-center p-4 border-0 shadow-sm">
                                    <div className="card-icon mb-3">
                                        <i class="fas fa-handshake fa-3x text-rose"></i>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title">Customer-Centric Approach</h5>
                                        <p className="text-secondary">At Tattva Hi Life, we ensure a seamless experience from start to finish, providing personalized service, transparent communication, and timely delivery. We work to understand your unique needs, delivering homes that exceed expectations.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            {/* WAVEBenefits */}
            {/* Project Gallery */}
            <div className='gallery container-fluid py-4'>
                <div className='gallery-inner container py-4'>
                    <h1 className='syne-title'>GALLERY<span className='text-rose'>.</span></h1>
                    <div className='row'>
                        <div className='col-lg-3 my-3 h-100'>
                            <img src={Project_Gallery_One} className='img-fluid' />
                        </div>
                        <div className='col-lg-3 my-3 h-100'>
                            <img src={Project_Gallery_Two} className='img-fluid' />
                        </div>
                        <div className='col-lg-3 my-3 h-100'>
                            <img src={Project_Gallery_Three} className='img-fluid' />
                        </div>
                        <div className='col-lg-3 my-3 h-100'>
                            <img src={Project_Gallery_Four} className='img-fluid' />
                        </div>
                        <div className='col-lg-3 my-3 h-100'>
                            <img src={Project_Gallery_Five} className='img-fluid' />
                        </div>
                        <div className='col-lg-3 my-3 h-100'>
                            <img src={Project_Gallery_Six} className='img-fluid' />
                        </div>
                        <div className='col-lg-3 my-3 h-100'>
                            <img src={Project_Gallery_Seven} className='img-fluid' />
                        </div>
                        <div className='col-lg-3 my-3 h-100'>
                            <img src={Project_Gallery_Eight} className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>
            {/* Project Gallery */}
            {/* Contact Map */}
            <div className='contact-map container'>
                <div className='container row py-4 justify-content-center w-100'>
                    <div className='col-lg-6 py-4'>
                        <h1 className='syne-title text-start'>LOCATION<span className='text-rose'>.</span></h1>
                        <p className='text-secondary text-start'>Explore our diverse range of locations, each offering unique experiences and exceptional services tailored to meet your needs.</p>
                    </div>
                    <div className='col-lg-6'></div>
                </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14458.513590838567!2d55.2859963!3d25.046682!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6f1de64a9b4b%3A0xfdc7e48466d6a5aa!2sReportage%20Village!5e0!3m2!1sen!2sin!4v1730192301343!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            {/* Contact Map */}
            {/* Home Discuss*/}
            <div className='container-fluid py-5 home-discuss'>
                <div className='container py-5 home-discuss-inner bg-light p-4 rounded-15 shadow-sm'>
                    <h1 className='syne-title mb-4 text-start'>
                        LET'S DISCUSS<br />YOUR IDEA<span className='text-rose'>.</span>
                    </h1>
                    <div className='discuss-inner'>
                        <div className='row align-items-center'>
                            <div className='col-lg-6'>
                                <div className='discuss-left text-start'>
                                    <p className='text-secondary'>
                                        We’re excited to hear your vision! Whether you have a concept or need help brainstorming, our team is ready to collaborate. Let’s connect and turn your idea into reality—schedule a meeting with us today!
                                    </p>
                                    <div className='discuss-mail d-flex align-items-center my-3'>
                                        <i className='fas fa-envelope me-2'></i>
                                        <p className='mb-0 text-secondary'>rakesh.tattvahilife@gmail.com</p>
                                    </div>
                                    <div className='discuss-mail d-flex align-items-center my-3'>
                                        <i className='fas fa-mobile-alt me-2'></i>
                                        <p className='mb-0 text-secondary'>+91 88970 35800</p>
                                    </div>
                                    <div className='discuss-mail d-flex align-items-center my-3'>
                                        <i className='fas fa-map-marker-alt me-2'></i>
                                        <p className='mb-0 text-secondary'>10, Prashanthi Hills, Khajaguda, Gachibowli, Hyderabad</p>
                                    </div>
                                    <div className='discuss-icons d-flex'>
                                        <a href='/about' target='blank' className='text-decoration-none'><i className='fab fa-linkedin-in text-white'></i></a>
                                        <a href='#' className='text-decoration-none'><i className='fab fa-twitter text-white'></i></a>
                                        <a href='#' className='text-decoration-none'><i className='fab fa-facebook-f text-white'></i></a>
                                        <a href='#' className='text-decoration-none'><i className='fab fa-instagram text-white'></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <form action="https://api.web3forms.com/submit" method="POST">
                                    <input type="hidden" name="access_key" value="d567d7ce-8cc7-4fdd-a9ae-66c9c3461e68" />
                                    <input
                                        type='text'
                                        placeholder='Name'
                                        name="name"
                                        className='form-control my-2'
                                        required

                                    />
                                    <input
                                        type='text'
                                        placeholder='Mobile'
                                        name="mobile"
                                        className='form-control my-2'
                                        required
                                    />
                                    <input
                                        type='email'
                                        placeholder='Email'
                                        name="email"
                                        className='form-control my-2'
                                        required
                                    />
                                    <textarea
                                        placeholder='Message'
                                        name="message"
                                        rows='4'
                                        className='form-control my-2'
                                        required
                                    />
                                    <div className='text-start mt-3 d-flex align-items-center'>
                                        <span className='fs-2 text-rose'>→</span>
                                        <input type='submit' value='Send' className='border-0 text-rose discuss-btn' />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Home Discuss*/}
        </div>
    )
}

export default ReportedVillage