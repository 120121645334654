import React from 'react'
import './Location.css'
import './Home.css'

function PrivacyPolicy() {
    return (
        <div className='location-main'>
            <div className='container-fluid'>
                <div className="container py-5">
                    <h1 className="text-center syne-title mb-4">Privacy Policy<span className='text-rose'>.</span></h1>

                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <p className='text-secondary'>
                                At Tattva Hi Life, we are committed to safeguarding your privacy. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you interact with us, whether through our website or through our services.
                            </p>

                            <h4 className='syne-title py-3'>Information Collection and Use</h4>
                            <p className='text-secondary'>
                                We collect both personal and non-personal information to provide you with our services effectively. Personal information includes details such as your name, email, phone number, and any other data you voluntarily provide. Non-personal information may include browser details, IP addresses, and activity logs to help us improve our website's functionality.
                            </p>

                            <h4 className='syne-title py-3'>How We Use Your Information</h4>
                            <p className='text-secondary'>
                                Information collected is primarily used to:
                                <ul className='list-unstyled'>
                                    <li className='my-2'><i class="fas fa-check-double text-rose me-2"></i> Deliver and enhance our real estate services, tailored to meet your needs.</li>
                                    <li className='my-2'><i class="fas fa-check-double text-rose me-2"></i>Conduct research and analysis to improve our services and customer experience.</li>
                                    <li className='my-2'><i class="fas fa-check-double text-rose me-2"></i>Communicate with you regarding your inquiries, project updates, and service offerings.</li>
                                    <li className='my-2'><i class="fas fa-check-double text-rose me-2"></i>Ensure security and compliance with legal obligations.</li>
                                </ul>
                            </p>

                            <h4 className='syne-title py-3'>Third-Party Disclosure</h4>
                            <p className='text-secondary'>
                                We may share your information with trusted third-party service providers to assist us in delivering services, such as data analytics and customer support. These third parties are obligated to maintain the confidentiality and security of your information.
                            </p>

                            <h4 className='syne-title py-3'>Data Security and Retention</h4>
                            <p className='text-secondary'>
                                Tattva Hi Life implements strict security measures to protect your data from unauthorized access, alteration, and misuse. Personal data is retained only for as long as necessary to fulfill the purposes outlined in this policy, or as required by law.
                            </p>

                            <h4 className='syne-title py-3'>Updates to Privacy Policy</h4>
                            <p className='text-secondary'>
                                Our Privacy Policy may change periodically to reflect updates in our services or legal obligations. We encourage you to review this policy regularly to stay informed.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Home Discuss*/}
            <div className='container-fluid py-5 home-discuss'>
                <div className='container py-5 home-discuss-inner bg-light p-4 rounded-15 shadow-sm'>
                    <h1 className='syne-title mb-4 text-start'>
                        LET'S DISCUSS<br />YOUR IDEA<span className='text-rose'>.</span>
                    </h1>
                    <div className='discuss-inner'>
                        <div className='row align-items-center'>
                            <div className='col-lg-6'>
                                <div className='discuss-left text-start'>
                                    <p className='text-secondary'>
                                        We’re excited to hear your vision! Whether you have a concept or need help brainstorming, our team is ready to collaborate. Let’s connect and turn your idea into reality—schedule a meeting with us today!
                                    </p>
                                    <div className='discuss-mail d-flex align-items-center my-3'>
                                        <i className='fas fa-envelope me-2'></i>
                                        <p className='mb-0 text-secondary'>rakesh.tattvahilife@gmail.com</p>
                                    </div>
                                    <div className='discuss-mail d-flex align-items-center my-3'>
                                        <i className='fas fa-mobile-alt me-2'></i>
                                        <p className='mb-0 text-secondary'>+91 88970 35800</p>
                                    </div>
                                    <div className='discuss-mail d-flex align-items-center my-3'>
                                        <i className='fas fa-map-marker-alt me-2'></i>
                                        <p className='mb-0 text-secondary'>10, Prashanthi Hills, Khajaguda, Gachibowli, Hyderabad</p>
                                    </div>
                                    <div className='discuss-icons d-flex'>
                                        <a href='/about' target='blank' className='text-decoration-none'><i className='fab fa-linkedin-in text-white'></i></a>
                                        <a href='#' className='text-decoration-none'><i className='fab fa-twitter text-white'></i></a>
                                        <a href='#' className='text-decoration-none'><i className='fab fa-facebook-f text-white'></i></a>
                                        <a href='#' className='text-decoration-none'><i className='fab fa-instagram text-white'></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <form action="https://api.web3forms.com/submit" method="POST">
                                    <input type="hidden" name="access_key" value="d567d7ce-8cc7-4fdd-a9ae-66c9c3461e68" />
                                    <input
                                        type='text'
                                        placeholder='Name'
                                        name="name"
                                        className='form-control my-2'
                                        required

                                    />
                                    <input
                                        type='text'
                                        placeholder='Mobile'
                                        name="mobile"
                                        className='form-control my-2'
                                        required
                                    />
                                    <input
                                        type='email'
                                        placeholder='Email'
                                        name="email"
                                        className='form-control my-2'
                                        required
                                    />
                                    <textarea
                                        placeholder='Message'
                                        name="message"
                                        rows='4'
                                        className='form-control my-2'
                                        required
                                    />
                                    <div className='text-start mt-3 d-flex align-items-center'>
                                        <span className='fs-2 text-rose'>→</span>
                                        <input type='submit' value='Send' className='border-0 text-rose discuss-btn' />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Home Discuss*/}
        </div>
    )
}

export default PrivacyPolicy