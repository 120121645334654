import React, { useEffect, useState } from 'react';
import Home_Hero_One from '../images/Tattva Promo 1.mp4'
import Home_Hero_Two from '../images/Tattva Promo 2.mp4'
import Home_Hero_One_Tablet from '../images/Tattva Hyderabad Promo (Tab Version).mp4'
import Home_Hero_Two_Tablet from '../images/Tattva Dubai Promo (Tab Version).mp4'
import Home_Hero_One_Mobile from '../images/Tattva Hyderabad Promo (Mobile Version).mp4'
import Home_Hero_Two_Mobile from '../images/Tattva Dubai Promo (Mobile Version).mp4'
import Carousel from 'react-bootstrap/Carousel';
import { FaXTwitter } from "react-icons/fa6";

import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css'
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import Home_Project_One from '../images/home-project-one-1.jpg';
import Home_Project_Two from '../images/home-project-two.jpg';
import Home_Project_Three from '../images/home-project-three.jpg';
import Home_Project_Four from '../images/home-project-four.jpg';
import Home_Project_Five from '../images/home-project-five.jpg';
import Home_Project_Six from '../images/home-project-six.jpg';
import Home_Project_Seven from '../images/home-project-seven.jpg';
import Home_Project_Eight from '../images/home-project-eight.jpg';
import Home_Project_Nine from '../images/home-project-nine.jpeg';
import Home_Project_Ten from '../images/asbl-project.jpg';
import Home_Project_Eleven from '../images/asbl-spectra.jpg';
import Home_Location_One from '../images/home-location-one.jpg';
import Home_Location_Two from '../images/home-location-two.jpg';
import Home_Location_Three from '../images/home-location-three.jpg';
import Quote_Icon from '../images/quote.png'
import Testi_Line from '../images/testi-line.png'
import Testi_One from '../images/testi-one.png'
import Testi_Two from '../images/testi-two.png'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Faq from "react-faq-component";



function Home() {
  const data = {
    rows: [
      {
        title: "What types of properties do you offer?",
        content: `At Tattva Hi Life, we specialize in a wide range of properties, including residential homes, commercial spaces, and luxury apartments. Whether you’re looking to buy, sell, or rent, we have listings that cater to various preferences and budgets.`,
      },
      {
        title: "How can I schedule a property viewing?",
        content: `Scheduling a property viewing is easy! You can contact our sales team through our website or call our office directly. We’ll arrange a convenient time for you to visit the property and answer any questions you may have.`,
      },
      {
        title: "What should I consider when buying a property?",
        content: `When buying a property, consider factors such as location, budget, property size, and amenities. Additionally, it's important to review legal documentation, zoning regulations, and potential future developments in the area. Our team is here to guide you through the process and provide expert advice.`,
      },
      {
        title: "Do you offer assistance with financing options?",
        content: `Yes, we provide assistance with various financing options to help you secure the best mortgage rates. Our financial advisors can guide you through the different types of loans available and help you find a solution that fits your financial situation.`,
      },
      {
        title: "What is the current version of your website platform?",
        content: <p>The current version of our website platform is 1.2.1. We continuously work to enhance your experience with updates and new features that improve functionality and usability.</p>,
      },
    ],

  };

  const styles = {
    // bgColor: 'white',
    // titleTextColor: "blue",
    // rowTitleColor: "blue",
    // rowContentColor: 'grey',
    // arrowColor: "red",
  };

  const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
  };


  return (
    <div className='home-main'>
      {/* Home Hero Video */}
      {/* <Carousel controls={false} indicators={false}>
        <Carousel.Item>
          <video src={Home_Hero_One} autoPlay muted loop className="d-block w-100" />
        </Carousel.Item>
      </Carousel> */}
      {/* <Carousel controls={false} indicators={false}>
        <Carousel.Item>
          <video src={Home_Hero_Two} autoPlay muted loop className="d-block w-100" />
        </Carousel.Item>
      </Carousel> */}
      {/* Home Hero Video */}

      {/* Dubai Video Section */}
      <video src={Home_Hero_One} autoPlay loop muted className='video-full d-lg-block d-none'></video>
      <video src={Home_Hero_One_Tablet} autoPlay loop muted className='video-full d-md-block d-lg-none d-none '></video>
      <video src={Home_Hero_One_Mobile} autoPlay loop muted className='video-full d-block d-md-none '></video>
      {/* Dubai Video Section */}
      {/* Home About */}
      <div className='home-about container-fluid py-4'>
        <div className='home-about-inner container py-4'>
          <div className='row justify-content-center '>
            {/* Content on the Right Side */}
            <div className='col-lg-8 col-md-10 my-3 d-flex align-items-center justify-content-center '>
              <div className='home-about-content'>
                <h1 className='syne-title'>ABOU<span className='text-rose'>T</span> US<span className='text-rose'>.</span></h1>
                <h3 className='fw-bold mb-4'><span className='text-rose'>Tattva Hi Life:</span> Hyderabad's Leading Real Estate PropTech, Advisory, Market Research & Analytics Firm.</h3>
                <p className='text-secondary'>It is a one-stop solution for all your real estate needs in Hyderabad. Founded in
                  2017, It has grown to become the largest and most trusted real estate
                  consultancy, market research, analytics and construction firm in the city.
                </p>
                <p className='text-secondary'>It offers a wide range of services, the company excels in various facets of the
                  real estate lifecycle, from initial land acquisition to post-sales activities.
                </p>
                <p className='text-secondary'>At the heart of Tattva Hi Life's operations lies its proficiency in Land
                  acquisition Conducting Market Analysis, Feasibility Studies, Project
                  Planning, Development Advisory, Marketing and Sales Strategy,
                  Investment and Valuation Advisory, Asset Management, Legal and
                  Regulatory Compliance - Providing data-driven insights and
                  recommendations based on the latest trends and technologies in the real estate
                  industry.
                </p>
                <p className='text-secondary'>It has a team of highly qualified and experienced professionals who have indepth knowledge and expertise in the property sector. We uses the best tools
                  and techniques to deliver high-quality and reliable services to its clients.
                </p>
                <p className='text-secondary'>Our vision is to be the ultimate destination for real estate solutions in
                  Hyderabad and beyond. Our mission is to create value for its clients by
                  providing them with innovative and customized solutions that meet their needs
                  and expectations.

                </p>
                <p className='text-secondary'>In essence, Tattva Hi Life's comprehensive suite of services and unwavering
                  commitment to excellence position it as a leader in the real estate prop-tech
                  landscape, shaping the future of urban living one development at a time.

                </p>
                <div className='home-about-btn text-start'>
                  <Link to="/about" className="text-rose"><FaLongArrowAltRight /> Read More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Home About */}
      {/* Dubai Video Section */}
      <video src={Home_Hero_Two} autoPlay loop muted className='video-full d-lg-block d-none'></video>
      <video src={Home_Hero_Two_Tablet} autoPlay loop muted className='video-full d-md-block d-lg-none d-none '></video>
      <video src={Home_Hero_Two_Mobile} autoPlay loop muted className='video-full d-block d-md-none '></video>
      {/* Dubai Video Section */}


      {/* Home Locations */}
      <div className='home-location container-fluid py-4 bg-light'>
        <div className='home-location-inner container py-4'>
          <h1 className='syne-title text-center text-uppercase'>Our Loca<span className='text-rose'>t</span>ions<span className='text-rose'>.</span></h1>
          <p className='mb-4 text-secondary'>At Tattva Hi Life, we pride ourselves on selecting prime locations that embody the perfect blend of accessibility, luxury, and potential for growth. Our real estate offerings span Hyderabad's most prestigious neighborhoods, each handpicked to deliver exceptional value to both investors and homeowners. Whether it's the heart of the city or serene suburban surroundings, our locations promise unmatched convenience, connectivity, and a lifestyle that stands above the rest.</p>
          <div className='home-location-imgs container-fluid'>
            {/* //// */}
            <div className='row'>
              <div className='col-lg-4 col-md-6 my-2'>
                <Link to='/location-hyderabad'>
                  <div className='loc-one'>
                    <div className='loc-shadow'>
                      <h4 className='syne-title'><span className="text-rose ">H</span>YDERABAD</h4>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6 my-2'>
                <Link to='/location-dubai'>
                  <div className='loc-three'>
                    <div className='loc-shadow'>
                      <h4 className='syne-title'><span className="text-rose ">D</span>UBAI</h4>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6 my-2'>
                <Link to='/location-banglore'>
                  <div className='loc-two'>
                    <div className='loc-shadow'>
                      <h4 className='syne-title'><span className="text-rose ">B</span>ANGLORE</h4>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            {/* //// */}
          </div>



        </div>
      </div>
      {/* Home Locations */}
      {/* Home Projects */}
      <div className='home-landowner container-fluid py-4'>
        <div className='home-landowner-inner container py-4'>
          <h1 className='syne-title text-center mb-3 text-uppercase'>Our Projec<span className='text-rose'>t</span>s<span className='text-rose'>.</span></h1>
          <p className='mb-4 text-secondary'>Our Project Partnership offers property owners a unique chance to collaborate with Tattva Hi Life in lucrative real estate developments. By partnering with us, owners can leverage their land to secure a substantial share in project profits without the complexities of construction management. With our expert guidance and commitment to transparency, we help property owners maximize their returns while they retain control over their valuable assets.</p>
          <div className='home-land-cards'>
            <div className='row'>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Ten} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>ASBL Spire</h4>
                    <p className='mb-1'>Kokapet, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-asbl-spire" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Eleven} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>ASBL Spectra</h4>
                    <p className='mb-1'>Financial District</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-asbl-spectra" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_One} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>IRIS By Raghava</h4>
                    <p className='mb-1'>Raidugam, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-iris" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Two} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Wave By Raghava</h4>
                    <p className='mb-1'>Tellapur, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-wave" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Three} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Pearl By Auro Reality</h4>
                    <p className='mb-1'>Hi-Tech City, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-pearl" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Four} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Rajpushpa Pristinia</h4>
                    <p className='mb-1'>Kokapet, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-rajpushpa" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Five} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>My Home Apas</h4>
                    <p className='mb-1'>Kokapet, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-my-home" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Six} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Upcoming Luxury Apartments</h4>
                    <p className='mb-1'>Neopolis, Kokapet</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-upcoming" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Seven} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Upcoming Luxury Apartments</h4>
                    <p className='mb-1'>Narsingi, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-upcoming" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Eight} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Candeur Lakescape</h4>
                    <p className='mb-1'>Kondapur, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-candeur" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Nine} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Luxury Apartments</h4>
                    <p className='mb-1'>Kondapur, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-luxury" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Home Projects */}
      <div className='home-project container-fluid py-4 bg-light'>
        <div className='container py-4'>
          <div className='row'>

            {/* Project List with Hover Effect */}
            <div className='project-list mt-4 pb-5'>
              {/* Project Item 1 */}
              <div className='project-item py-3 position-relative my-2'>
                <Link to='/project-asbl-spire'>
                  <div className='row align-items-center'>
                    <div className='col-4 text-start'>
                      <h6 className=' mb-0'>ASBL Spire</h6>
                    </div>
                    <div className='col-1'>
                      <span className=''>2023</span>
                    </div>
                    <div className='col-6'>
                      <h5 className='text-center project-name'>ASBL Spire</h5>
                      <div className='project-hover-content position-absolute'>

                        <img src={Home_Project_Ten} alt="Raj Pushpa Project" className='hover-image' />
                        <div className='hover-info text-center'>
                          <p className='mb-0 fs-7'>Kokapet</p>
                          <p className='text-secondary'>
                            {/* <span className='text-rose fs-4 fw-bold'>70,000 Sq ft</span> */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='col-1 project-arrow'>
                      <i className="fa-solid fa-arrow-right"></i>
                    </div>
                  </div>
                </Link>

              </div>
              {/* Project Item 1 */}
              <div className='project-item py-3 position-relative my-2'>
                <Link to='/project-asbl-spire'>
                  <div className='row align-items-center'>
                    <div className='col-4 text-start'>
                      <h6 className=' mb-0'>ASBL Spectra</h6>
                    </div>
                    <div className='col-1'>
                      <span className=''>2023</span>
                    </div>
                    <div className='col-6'>
                      <h5 className='text-center project-name'>ASBL Spectra</h5>
                      <div className='project-hover-content position-absolute'>

                        <img src={Home_Project_Eleven} alt="Raj Pushpa Project" className='hover-image' />
                        <div className='hover-info text-center'>
                          <p className='mb-0 fs-7'>Kokapet</p>
                          <p className='text-secondary'>
                            {/* <span className='text-rose fs-4 fw-bold'>70,000 Sq ft</span> */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='col-1 project-arrow'>
                      <i className="fa-solid fa-arrow-right"></i>
                    </div>
                  </div>
                </Link>

              </div>
              {/* Project Item 1 */}
              <div className='project-item py-3 position-relative my-2'>
                <Link to='/project-iris'>
                  <div className='row align-items-center'>
                    <div className='col-4 text-start'>
                      <h6 className=' mb-0'>IRIS By Raghava </h6>
                    </div>
                    <div className='col-1'>
                      <span className=''>2023</span>
                    </div>
                    <div className='col-6'>
                      <h5 className='text-center project-name'>IRIS By Raghava</h5>
                      <div className='project-hover-content position-absolute'>

                        <img src={Home_Project_One} alt="Raj Pushpa Project" className='hover-image' />
                        <div className='hover-info text-center'>
                          <p className='mb-0 fs-7'>Raidurgam</p>
                          <p className='text-secondary'>
                            {/* <span className='text-rose fs-4 fw-bold'>70,000 Sq ft</span> */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='col-1 project-arrow'>
                      <i className="fa-solid fa-arrow-right"></i>
                    </div>
                  </div>
                </Link>

              </div>
              {/* Project Item 2 */}
              <div className='project-item py-3 position-relative my-2'>
                <Link to='/project-wave'>
                  <div className='row align-items-center'>
                    <div className='col-4 text-start'>
                      <h6 className=' mb-0'>Wave By Raghava</h6>
                    </div>
                    <div className='col-1'>
                      <span className=''>2023</span>
                    </div>
                    <div className='col-6'>
                      <h5 className='text-center project-name'>Wave By Raghava</h5>
                      <div className='project-hover-content position-absolute'>

                        <img src={Home_Project_Two} alt="Raj Pushpa Project" className='hover-image' />
                        <div className='hover-info text-center'>
                          <p className='mb-0 fs-7'>Tellapur</p>
                          <p className='text-secondary'>
                            {/* From <span className='text-rose fs-4 fw-bold'>3.0CR</span> */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='col-1 project-arrow'>
                      <i className="fa-solid fa-arrow-right"></i>
                    </div>
                  </div>
                </Link>

              </div>
              {/* Project Item 3 */}
              <div className='project-item py-3 position-relative my-2'>
                <Link to='/project-pearl'>
                  <div className='row align-items-center'>
                    <div className='col-4 text-start'>
                      <h6 className=' mb-0'>PEARL By Auro Reality</h6>
                    </div>
                    <div className='col-1'>
                      <span className=''>2023</span>
                    </div>
                    <div className='col-6'>
                      <h5 className='text-center project-name'>PEARL By Auro Reality</h5>
                      <div className='project-hover-content position-absolute'>

                        <img src={Home_Project_Three} alt="Raj Pushpa Project" className='hover-image' />
                        <div className='hover-info text-center'>
                          <p className='mb-0 fs-7'>Hi-Tech City</p>
                          <p className='text-secondary'>
                            {/* From <span className='text-rose fs-4 fw-bold'>3.0CR</span> */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='col-1 project-arrow'>
                      <i className="fa-solid fa-arrow-right"></i>
                    </div>
                  </div>
                </Link>

              </div>
              {/* Project Item 4 */}
              <div className='project-item py-3 position-relative my-2'>
                <Link to="/project-rajpushpa">
                  <div className='row align-items-center'>
                    <div className='col-4 text-start'>
                      <h6 className=' mb-0'>Rajpushpa Pristinia</h6>
                    </div>
                    <div className='col-1'>
                      <span className=''>2023</span>
                    </div>
                    <div className='col-6'>
                      <h5 className='text-center project-name'>Rajpushpa Pristinia</h5>
                      <div className='project-hover-content position-absolute'>

                        <img src={Home_Project_Four} alt="Raj Pushpa Project" className='hover-image' />
                        <div className='hover-info text-center'>
                          <p className='mb-0 fs-7'>Kokapet, Hyderabad</p>
                          <p className='text-secondary'>
                            {/* From <span className='text-rose fs-4 fw-bold'>3.0CR</span> */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='col-1 project-arrow'>
                      <i className="fa-solid fa-arrow-right"></i>
                    </div>
                  </div>
                </Link>

              </div>
              {/* Project Item 5 */}
              <div className='project-item py-3 position-relative my-2'>
                <Link to='/project-my-home'>
                  <div className='row align-items-center'>
                    <div className='col-4 text-start'>
                      <h6 className=' mb-0'>My Homes Apas</h6>
                    </div>
                    <div className='col-1'>
                      <span className=''>2023</span>
                    </div>
                    <div className='col-6'>
                      <h5 className='text-center project-name'>My Homes Apas</h5>
                      <div className='project-hover-content position-absolute'>

                        <img src={Home_Project_Five} alt="Raj Pushpa Project" className='hover-image' />
                        <div className='hover-info text-center'>
                          <p className='mb-0 fs-7'>Kokapet, Hyderabad</p>
                          <p className='text-secondary'>
                            {/* From <span className='text-rose fs-4 fw-bold'>3.0CR</span> */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='col-1 project-arrow'>
                      <i className="fa-solid fa-arrow-right"></i>
                    </div>
                  </div>
                </Link>

              </div>
              {/* Project Item 6 */}
              <div className='project-item py-3 position-relative my-2'>
                <Link to='/project-upcoming'>
                  <div className='row align-items-center'>
                    <div className='col-4 text-start'>
                      <h6 className=' mb-0'>Upcoming Luxury Apartments</h6>
                    </div>
                    <div className='col-1'>
                      <span className=''>2023</span>
                    </div>
                    <div className='col-6'>
                      <h5 className='text-center project-name'>Upcoming Luxury Apartments</h5>
                      <div className='project-hover-content position-absolute'>

                        <img src={Home_Project_Six} alt="Raj Pushpa Project" className='hover-image' />
                        <div className='hover-info text-center'>
                          <p className='mb-0 fs-7'>Neopolis, Kokapet</p>
                          <p className='text-secondary'>
                            {/* From <span className='text-rose fs-4 fw-bold'>3.0CR</span> */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='col-1 project-arrow'>
                      <i className="fa-solid fa-arrow-right"></i>
                    </div>
                  </div>
                </Link>

              </div>
              {/* Project Item 7 */}
              <div className='project-item py-3 position-relative my-2'>
                <Link to='/project-upcoming'>
                  <div className='row align-items-center'>
                    <div className='col-4 text-start'>
                      <h6 className=' mb-0'>Upcoming Luxury Apartments</h6>
                    </div>
                    <div className='col-1'>
                      <span className=''>2023</span>
                    </div>
                    <div className='col-6'>
                      <h5 className='text-center project-name'>Upcoming Luxury Apartments</h5>
                      <div className='project-hover-content position-absolute'>

                        <img src={Home_Project_Seven} alt="Raj Pushpa Project" className='hover-image' />
                        <div className='hover-info text-center'>
                          <p className='mb-0 fs-7'>Narsingi</p>
                          <p className='text-secondary'>
                            {/* From <span className='text-rose fs-4 fw-bold'>3.0CR</span> */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='col-1 project-arrow'>
                      <i className="fa-solid fa-arrow-right"></i>
                    </div>
                  </div>
                </Link>

              </div>
              {/* Project Item 8 */}
              <div className='project-item py-3 position-relative my-2'>
                <Link to='/project-candeur'>
                  <div className='row align-items-center'>
                    <div className='col-4 text-start'>
                      <h6 className=' mb-0'>Candeur Lakescape</h6>
                    </div>
                    <div className='col-1'>
                      <span className=''>2023</span>
                    </div>
                    <div className='col-6'>
                      <h5 className='text-center project-name'>Candeur Lakescape</h5>
                      <div className='project-hover-content position-absolute'>

                        <img src={Home_Project_Eight} alt="Raj Pushpa Project" className='hover-image' />
                        <div className='hover-info text-center'>
                          <p className='mb-0 fs-7'>Kondapur, Hyderabad</p>
                          <p className='text-secondary'>
                            {/* From <span className='text-rose fs-4 fw-bold'>3.0CR</span> */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='col-1 project-arrow'>
                      <i className="fa-solid fa-arrow-right"></i>
                    </div>
                  </div>
                </Link>

              </div>
              {/* Project Item 9 */}
              <div className='project-item py-3 position-relative my-2'>
                <Link to='/project-luxury'>
                  <div className='row align-items-center'>
                    <div className='col-4 text-start'>
                      <h6 className=' mb-0'>Luxury Apartments</h6>
                    </div>
                    <div className='col-1'>
                      <span className=''>2023</span>
                    </div>
                    <div className='col-6'>
                      <h5 className='text-center project-name'>Luxury Apartments</h5>
                      <div className='project-hover-content position-absolute'>

                        <img src={Home_Project_Nine} alt="Raj Pushpa Project" className='hover-image' />
                        <div className='hover-info text-center'>
                          <p className='mb-0 fs-7'>Kondapur</p>
                          <p className='text-secondary'>
                            {/* From <span className='text-rose fs-4 fw-bold'>3.0CR</span> */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='col-1 project-arrow'>
                      <i className="fa-solid fa-arrow-right"></i>
                    </div>
                  </div>
                </Link>
              </div>
              {/* Additional project items can follow the same structure */}
            </div>
          </div>
        </div>
      </div>
      {/* Home Projects*/}
      {/* Home Testimonials */}
      <div className='home-founders container-fluid py-4'>
        <div className='home-founders container py-4'>
          <div className='row mb-4 container'>
            <div className='col-lg-8 text-start'>
              <h1 className='syne-title'>WHA<span className='text-rose'>T</span><br /> OUR CLIEN<span className='text-rose'>T</span>S SAY<span className='text-rose'>!</span></h1>
            </div>
          </div>
          <Slider
            dots={false}
            infinite={true}
            speed={500}
            slidesToShow={2}
            slidesToScroll={1}
            arrows={true}
            autoplay={true} // Enable auto sliding
            autoplaySpeed={3000} // Set the duration for each slide in milliseconds
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                },
              },
            ]}
          >
            {/* Testi one */}
            <div className='home-testi row d-flex justify-content-center'>
              <div className='home-testi-inner col-md-10'>
                <div className='testi-one-inner-top d-flex align-items-end'>
                  <img src={Quote_Icon} className="w-4 me-2" />
                  <p className='fw-bold mb-0'>A Home Beyond Expectations</p>
                </div>
                <p className='text-secondary text-start my-2'>Living at Tattva Hi Life has been an extraordinary experience. The attention to detail in every aspect of the design is impeccable, from the spacious layouts to the luxurious finishes. The amenities and serene environment truly elevate the living standard. We couldn't have asked for a better place to call home.</p>
                <img src={Testi_Line} className='img-fluid mt-3' />
                <div className='row'>
                  <div className='col-2'></div>
                  <div className='col-10 d-flex align-items-center testi-one-inner-bottom'>
                    <img src={Testi_One} className='w-15 rounded-circle' />
                    <div className='text-start ms-3'>
                      <p className='fw-bold mb-1'>John Kevin</p>
                      <div className='test-rating'>
                        <i class="fas fa-star text-rose"></i>
                        <i class="fas fa-star text-rose"></i>
                        <i class="fas fa-star text-rose"></i>
                        <i class="fas fa-star text-rose"></i>
                        <i class="fas fa-star text-rose"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Testi one */}
            <div className='home-testi row d-flex justify-content-center'>
              <div className='home-testi-inner col-md-10'>
                <div className='testi-one-inner-top d-flex align-items-end'>
                  <img src={Quote_Icon} className="w-4 me-2" />
                  <p className='fw-bold mb-0'>A Home Beyond Expectations</p>
                </div>
                <p className='text-secondary text-start my-2'>Living at Tattva Hi Life has been an extraordinary experience. The attention to detail in every aspect of the design is impeccable, from the spacious layouts to the luxurious finishes. The amenities and serene environment truly elevate the living standard. We couldn't have asked for a better place to call home.</p>
                <img src={Testi_Line} className='img-fluid mt-3' />
                <div className='row'>
                  <div className='col-2'></div>
                  <div className='col-10 d-flex align-items-center testi-one-inner-bottom'>
                    <img src={Testi_One} className='w-15 rounded-circle' />
                    <div className='text-start ms-3'>
                      <p className='fw-bold mb-1'>John Kevin</p>
                      <div className='test-rating'>
                        <i class="fas fa-star text-rose"></i>
                        <i class="fas fa-star text-rose"></i>
                        <i class="fas fa-star text-rose"></i>
                        <i class="fas fa-star text-rose"></i>
                        <i class="fas fa-star text-rose"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Testi one */}
            <div className='home-testi row d-flex justify-content-center'>
              <div className='home-testi-inner col-md-10'>
                <div className='testi-one-inner-top d-flex align-items-end'>
                  <img src={Quote_Icon} className="w-4 me-2" />
                  <p className='fw-bold mb-0'>A Home Beyond Expectations</p>
                </div>
                <p className='text-secondary text-start my-2'>Living at Tattva Hi Life has been an extraordinary experience. The attention to detail in every aspect of the design is impeccable, from the spacious layouts to the luxurious finishes. The amenities and serene environment truly elevate the living standard. We couldn't have asked for a better place to call home.</p>
                <img src={Testi_Line} className='img-fluid mt-3' />
                <div className='row'>
                  <div className='col-2'></div>
                  <div className='col-10 d-flex align-items-center testi-one-inner-bottom'>
                    <img src={Testi_One} className='w-15 rounded-circle' />
                    <div className='text-start ms-3'>
                      <p className='fw-bold mb-1'>John Kevin</p>
                      <div className='test-rating'>
                        <i class="fas fa-star text-rose"></i>
                        <i class="fas fa-star text-rose"></i>
                        <i class="fas fa-star text-rose"></i>
                        <i class="fas fa-star text-rose"></i>
                        <i class="fas fa-star text-rose"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      {/* Home Testimonials */}
      {/* Home FAQ'S */}
      <div className='container-fluid faq py-4'>
        <div className='container py-4'>
          <div className='row'>
            <div className='col-lg-3'>
              <h1 className='syne-title'>FAQ<span className='text-rose'>.</span></h1>
            </div>
            <div className='col-lg-9'>
              <Faq
                data={data}
                styles={styles}
                config={config}
              />
            </div>
          </div>

        </div>
      </div>
      {/* Home FAQ'S */}
      {/* Home Discuss*/}
      <div className='container-fluid py-5 home-discuss'>
        <div className='container py-5 home-discuss-inner bg-light p-4 rounded-15 shadow-sm'>
          <h1 className='syne-title mb-4 text-start'>
            LET'S DISCUSS<br />YOUR IDEA<span className='text-rose'>.</span>
          </h1>
          <div className='discuss-inner'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                <div className='discuss-left text-start'>
                  <p className='text-secondary'>
                    We’re excited to hear your vision! Whether you have a concept or need help brainstorming, our team is ready to collaborate. Let’s connect and turn your idea into reality—schedule a meeting with us today!
                  </p>
                  <div className='discuss-mail d-flex align-items-center my-3'>
                    <i className='fas fa-envelope me-2'></i>
                    <p className='mb-0 text-secondary'>rakesh.tattvahilife@gmail.com</p>
                  </div>
                  <div className='discuss-mail d-flex align-items-center my-3'>
                    <i className='fas fa-mobile-alt me-2'></i>
                    <p className='mb-0 text-secondary'>+91 88970 35800</p>
                  </div>
                  <div className='discuss-mail d-flex align-items-center my-3'>
                    <i className='fas fa-map-marker-alt me-2'></i>
                    <p className='mb-0 text-secondary'>10, Prashanthi Hills, Khajaguda, Gachibowli, Hyderabad</p>
                  </div>
                  <div className='discuss-icons d-flex'>
                    <a href='/about' target='blank' className='text-decoration-none'><i className='fab fa-linkedin-in text-white'></i></a>
                    <a href='#' className='text-decoration-none'><i className='fab fa-twitter text-white'></i></a>
                    <a href='#' className='text-decoration-none'><i className='fab fa-facebook-f text-white'></i></a>
                    <a href='#' className='text-decoration-none'><i className='fab fa-instagram text-white'></i></a>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <form action="https://api.web3forms.com/submit" method="POST">
                  <input type="hidden" name="access_key" value="d567d7ce-8cc7-4fdd-a9ae-66c9c3461e68" />
                  <input
                    type='text'
                    placeholder='Name'
                    name="name"
                    className='form-control my-2'
                    required

                  />
                  <input
                    type='text'
                    placeholder='Mobile'
                    name="mobile"
                    className='form-control my-2'
                    required
                  />
                  <input
                    type='email'
                    placeholder='Email'
                    name="email"
                    className='form-control my-2'
                    required
                  />
                  <textarea
                    placeholder='Message'
                    name="message"
                    rows='4'
                    className='form-control my-2'
                    required
                  />
                  <div className='text-start mt-3 d-flex align-items-center'>
                    <span className='fs-2 text-rose'>→</span>
                    <input type='submit' value='Send' className='border-0 text-rose discuss-btn' />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Home Discuss*/}
      {/* Brand Slider */}

      {/* Brand Slider */}


    </div>
  )
}

export default Home