import React, { useEffect } from 'react';
import favicon from './favicon.png';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home"; // Create individual pages for each section
import LandownerShare from "./pages/LandownerShare";
import About from "./pages/About";
import Location from "./pages/Location";
import Project from "./pages/Project";
import ContactUs from "./pages/ContactUs";
// Location Pages
import Hyderabad from './pages/Hyderabad.js';
import Banglore from './pages/Banglore.js';
import Dubai from './pages/Dubai.js';
// Project Pages
import ASBLSpire from './pages/ASBLSpire.js'
import ASBLSpectra from './pages/ASBLSpectra.js'
import IRIS from './pages/IRISByRaghava.js'
import Wave from './pages/WaveByRaghava.js'
import Pearl from './pages/PearlByAuroReality.js'
import Rajpushpa from './pages/RajpushpaPristinia.js'
import MyHome from './pages/MyHomeApas.js'
import UpcomingLuxury from './pages/UpcomingLuxury.js'
import Candeur from './pages/CandeurLandscape.js'
import Luxury from './pages/LuxuryApartments.js'
import Azizi from './pages/AziziVenice.js'
import Reportage from './pages/ReportedVillage.js'
import Damac from './pages/DamacLagoons.js'
import PrivacyPolicy from './pages/PrivacyPolicy.js';
import TermsAndConditions from './pages/TermsAndConditions.js';


function App() {
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = favicon;
    document.head.appendChild(link);
  }, []);

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/landowner-share" element={<LandownerShare />} />
        <Route path="/about" element={<About />} />
        <Route path="/location" element={<Location />} />
        <Route path="/projects" element={<Project />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/location-hyderabad" element={<Hyderabad />} />
        <Route path="/location-dubai" element={<Dubai />} />
        <Route path="/location-banglore" element={<Banglore />} />
        <Route path="/project-asbl-spire" element={<ASBLSpire />} />
        <Route path="/project-asbl-spectra" element={<ASBLSpectra />} />
        <Route path="/project-iris" element={<IRIS />} />
        <Route path="/project-wave" element={<Wave />} />
        <Route path="/project-pearl" element={<Pearl />} />
        <Route path="/project-rajpushpa" element={<Rajpushpa />} />
        <Route path="/project-my-home" element={<MyHome />} />
        <Route path="/project-upcoming" element={<UpcomingLuxury />} />
        <Route path="/project-candeur" element={<Candeur />} />
        <Route path="/project-luxury" element={<Luxury />} />
        <Route path="/project-azizi" element={<Azizi />} />
        <Route path="/project-reportage" element={<Reportage />} />
        <Route path="/project-damac" element={<Damac />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />

      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
